<template>
  <div
    @mouseover="hovering = true"
    @mouseleave="hovering = false">
    <b-card-group deck>
      <b-card class="video m-3" >
        <b-card-img
          class="video-thumbnail"
          :src="data.thumbnail"
          top
        ></b-card-img>
        <b-card-text
          class="text-center title mb-0"
          v-if="breakpoint == 'xs' || windowWidth >= 1600">
          {{ data.media_title | truncate(45) }}
        </b-card-text>
        <b-card-text class="text-center title mb-0" v-else>
          {{ data.media_title | truncate }}
        </b-card-text>
        <b-card-text class="text-center length text-muted">
          <b-icon icon="clock"></b-icon>
          {{ data.media_length | formatTime }}
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    mixins : [
      BreakpointMixin,
      FilterMixin,
    ],
    props : {
      data : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        hovering   : false,
        loading    : true,
        maxImages  : 3,
        graceCount : 3,
        imageIndex : 0,
        interval   : null,
        images     : [],
      }
    },
    computed : {
      thumbnailUrl() {
        const stillIndex =
          this.data.orig_thumbnail.indexOf('&video_still_time');
        const cropped = this.data.orig_thumbnail.substring(0, stillIndex) +
          '&video_still_time=';
        
        return cropped;
      },
    },
    watch : {
      hovering() {
        const imgElement = this.$el.querySelector('.video-thumbnail');
        if (this.hovering) {
          this.interval = setInterval(() => {
            imgElement.src = this.images[this.imageIndex];
            this.imageIndex = this.imageIndex < (this.maxImages - 1) ?
              this.imageIndex + 1 : 0;

            if (this.graceCount > 0)
              this.graceCount--;
            else {
              if (this.loading)
                this.loading = false;
            }
          }, 900);
        } else {
          imgElement.src = this.data.thumbnail;
          clearInterval(this.interval);
        }
      },
    },
    methods : {

      /**
       * Get images for animated thumbnails
       */
      getImages() {
        const max = Math.floor(this.data.media_length);

        for (let i = 0; i < this.maxImages; i++) {
          this.images.push(
            `${this.thumbnailUrl}${this.randomIntFromInterval(1, max)}`
          );
        }
      },

      /**
       * Get random integer from interval
       *
       * @param min
       * @param max
       * @return {number}
       */
      randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      },
    },
    mounted() {
      this.images = [];
      this.getImages();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";
  
  .title {
    padding: 0.5rem;
    color: $primary-color !important;
    line-height: 1;
    font-size: 0.8em !important;
    text-shadow: none !important;
    -webkit-text-stroke: transparent !important;
  }

  .length {
    padding-bottom: 0.5rem;
    text-shadow: none !important;
    font-size: 0.75em !important;
    -webkit-text-stroke: transparent !important;
  }

  .video {
    margin: 0.5rem 0;
    border: 1px solid $primary-color;
    transition: 0.5s transform;
    transform-origin: center center;
    &:hover {
      transform: scale(1.2);
    }
  }

  .card-body {
    padding: 0;
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .title {
      font-size: 1em !important;
    }
    .length {
      font-size: 0.85em !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .title {
      font-size: 1.1em !important;
    }
    .length {
      font-size: 0.9em !important;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .title {
      font-size: 1.25em !important;
    }
    .length {
      font-size: 1em !important;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .title {
      font-size: 1em !important;
    }
    .length {
      font-size: 0.8em !important;
    }
  }
  @media only screen and (min-width: $special) {}
  @media only screen and (min-width: $xl2-min) {
    .title {
      font-size: 0.8em !important;
    }
    .length {
      font-size: 0.7em !important;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>